<template>
<Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3" @toggle="onToggle">
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Project Code</label>
                <InputText v-model="forms.project_code" :class="{ 'p-invalid': errorAdd.project_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.project_code" >{{ errorAdd.project_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Project Name</label>
                <InputText v-model="forms.project_name" :class="{ 'p-invalid': errorAdd.project_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.project_name" >{{ errorAdd.project_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="period">Period</label>
                <Calendar :manualInput="false" v-model="period" view="month" dateFormat="MM yy"  icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorAdd.project_periode_month || errorAdd.project_periode_year }"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.project_periode_month || errorAdd.project_periode_year" >{{ errorAdd.project_periode_month[0] }}{{ errorAdd.project_periode_year[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Description</label>
                <Textarea v-model="forms.project_description" :autoResize="true" rows="5" cols="30" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.project_description" >{{ errorAdd.project_description[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Location</label>
                <Textarea v-model="forms.project_location" :autoResize="true" rows="5" cols="30" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.project_location" >{{ errorAdd.project_location[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor Coordinate</label>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Latitude</label>
                <InputText v-model="forms.distributor_latitude" :class="{ 'p-invalid': errorAdd.distributor_latitude }" placeholder="Latitude..."/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.distributor_latitude" >{{ errorAdd.distributor_latitude[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Longitude</label>
                <InputText v-model="forms.distributor_longitude" :class="{ 'p-invalid': errorAdd.distributor_longitude }" placeholder="Longitude..."/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.distributor_longitude" >{{ errorAdd.distributor_longitude[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-12 lg:col-12">
                <Button label="Refresh Map" icon="pi pi-refresh" class="p-button-secondary" @click="refreshMap" />
                <div class="map" id="map"></div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</Fieldset>
</template>

<script>

import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,

            // addNew
            forms: {
                project_code: null,
                project_name: null,
                project_location: null,
                project_description: null,
                distributor_latitude: null,
                distributor_longitude: null,
            },

            period: new Date(),

            //maps
            map: null,
            zoom: 5,
            lat: -0.756814,
            lng: 114.121093,
            marker: null,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.initMap();
    },
    computed:{
        ...mapGetters(['errorAdd']),

        period_month(){ 
            return moment(this.period, 'MM').format('MM'); 
        },
        period_year(){ 
            return moment(this.period, 'YYYY').format('YYYY'); 
        },
    },
    watch: {
        'forms.distributor_latitude': 'updateMarkerPosition',
        'forms.distributor_longitude': 'updateMarkerPosition',
    },
    methods: {
        onToggle() {
            setTimeout(() => {
                this.refreshMap();
            }, 500);
        },
        initMap(){
             setTimeout(() => {
                // eslint-disable-next-line no-undef
                this.map = L.map('map', {
                    attributionControl: false,
                    doubleClickZoom: true,
                    zoomControl: true,
                    zoom:1,
                    zoomAnimation:false,
                    fadeAnimation:true,
                    markerZoomAnimation:true
                }).setView([this.lat, this.lng], this.zoom);

                // eslint-disable-next-line no-undef
                L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(this.map);

                this.map.on('click', this.onMapClick);

                setTimeout(function () {
                    window.dispatchEvent(new Event('resize'));
                }, 1000);
                
             }, 500);
        },
        onMapClick(e) {
            if (this.marker) {
                this.map.removeLayer(this.marker);
            }
            // eslint-disable-next-line no-undef
            this.marker = L.marker(e.latlng).addTo(this.map);
            this.forms.distributor_latitude = e.latlng.lat.toFixed(6);
            this.forms.distributor_longitude = e.latlng.lng.toFixed(6);
        },  
        updateMarkerPosition() {
            if (this.forms.distributor_latitude && this.forms.distributor_longitude) {
                const lat = parseFloat(this.forms.distributor_latitude);
                const lng = parseFloat(this.forms.distributor_longitude);
                if (!isNaN(lat) && !isNaN(lng)) {
                    const latlng = [lat, lng];
                    if (this.marker) {
                        this.marker.setLatLng(latlng);
                    } else {
                        // eslint-disable-next-line no-undef
                        this.marker = L.marker(latlng).addTo(this.map);
                    }
                    this.map.setView(latlng);
                }
            }else{
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                    this.marker = null;
                }
            }
        },
        refreshMap() {
            this.map.invalidateSize();
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/project/create',
                data: {
                    "project_code": this.forms.project_code,
                    "project_name": this.forms.project_name,
                    "project_location": this.forms.project_location,
                    "project_description": this.forms.project_description,
                    "distributor_latitude": this.forms.distributor_latitude,
                    "distributor_longitude": this.forms.distributor_longitude,
                    "project_periode_month": this.period_month,
                    "project_periode_year": this.period_year
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.project_code = null;
            this.forms.project_name = null;
            this.forms.project_location = null;
            this.forms.project_description = null;
            this.forms.distributor_latitude = null;
            this.forms.distributor_longitude = null;
            this.period = new Date();
        },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
</style>
