<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '750px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Project Code</label>
            <InputText v-model="localItem.project_code" :class="{ 'p-invalid': errorEdit.project_code }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.project_code">{{ errorEdit.project_code[0] }}</small>
        </div>
        <div class="field">
            <label>Project Name</label>
            <InputText v-model="localItem.project_name" :class="{ 'p-invalid': errorEdit.project_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.project_name">{{ errorEdit.project_name[0] }}</small>
        </div>
        <div class="field">
            <label>Period</label>
            <Calendar :manualInput="false" v-model="period" view="month" dateFormat="MM yy" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorEdit.project_periode_month || errorEdit.project_periode_year }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.project_periode_month || errorEdit.project_periode_year">{{ errorEdit.project_periode_month[0] }}{{ errorEdit.project_periode_year[0] }}</small>
        </div>
        <div class="field">
            <label>Project Description</label>
            <Textarea v-model="localItem.project_description" :autoResize="true" rows="5" cols="30" :class="{ 'p-invalid': errorEdit.project_description }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.project_description">{{ errorEdit.project_description[0] }}</small>
        </div>
        <div class="field">
            <label>Project Location</label>
            <Textarea v-model="localItem.project_location" :autoResize="true" rows="5" cols="30" :class="{ 'p-invalid': errorEdit.project_location }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.project_location">{{ errorEdit.project_location[0] }}</small>
        </div>
        <div class="field">
            <label>Distributor Coordinate</label>
            <InputText v-model="localItem.distributor_latitude" placeholder="Latitude..." :class="{ 'p-invalid': errorEdit.distributor_latitude }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.distributor_latitude">{{ errorEdit.distributor_latitude[0] }}</small>
        </div>
        <div class="field">
            <InputText v-model="localItem.distributor_longitude" placeholder="Longitude..." :class="{ 'p-invalid': errorEdit.distributor_longitude }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.distributor_longitude">{{ errorEdit.distributor_longitude[0] }}</small>
        </div>
        <div class="field">
            <div class="map-edit" id="map-edit"></div>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    props: ["item"],
    data() {
        return {
            loading: false,
            dataDropdownStatus: [
                { name: 'ACTIVE', code: 'y' },
                { name: 'NON ACTIVE', code: 'n' },
            ],
            editItemDialog: false,
            period: null,
            localItem: { ...this.item },
            mapEdit: null,
            zoom: 5,
            lat: -0.756814,
            lng: 114.121093,
            marker: null,
        };
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if (this.editItemDialog) {
                        this.period = new Date(this.item.project_periode_label);
                        this.localItem = { ...this.item }; // Sync localItem with item prop
                        this.initMap();
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        },
        'localItem.distributor_latitude': 'updateMarkerPosition',
        'localItem.distributor_longitude': 'updateMarkerPosition',
    },
    computed: {
        ...mapGetters(['errorEdit']),
        period_month() {
            return moment(this.period, 'MM').format('MM');
        },
        period_year() {
            return moment(this.period, 'YYYY').format('YYYY');
        },
    },
    methods: {
        initMap() {
            // eslint-disable-next-line no-undef
            this.mapEdit = L.map('map-edit', {
                attributionControl: false,
                doubleClickZoom: true,
                zoomControl: true,
                zoom: 1,
                zoomAnimation: false,
                fadeAnimation: true,
                markerZoomAnimation: true
            }).setView([this.localItem.distributor_latitude, this.localItem.distributor_longitude], this.zoom);

            // eslint-disable-next-line no-undef
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.mapEdit);

            // eslint-disable-next-line no-undef
            this.marker = L.marker([this.localItem.distributor_latitude, this.localItem.distributor_longitude]).addTo(this.mapEdit);

            this.mapEdit.on('click', this.onMapClick);

            setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
            }, 1000);
        },
        onMapClick(e) {
            if (this.marker) {
                this.mapEdit.removeLayer(this.marker);
            }
            // eslint-disable-next-line no-undef
            this.marker = L.marker(e.latlng).addTo(this.mapEdit);
            this.localItem.distributor_latitude = e.latlng.lat.toFixed(6);
            this.localItem.distributor_longitude = e.latlng.lng.toFixed(6);
        },
        updateMarkerPosition() {
            if (this.localItem.distributor_latitude && this.localItem.distributor_longitude) {
                const lat = parseFloat(this.localItem.distributor_latitude);
                const lng = parseFloat(this.localItem.distributor_longitude);
                if (!isNaN(lat) && !isNaN(lng)) {
                    const latlng = [lat, lng];
                    if (this.marker) {
                        this.marker.setLatLng(latlng);
                    } else {
                        // eslint-disable-next-line no-undef
                        this.marker = L.marker(latlng).addTo(this.mapEdit);
                    }
                    this.mapEdit.setView(latlng);
                }
            } else {
                if (this.marker) {
                    this.mapEdit.removeLayer(this.marker);
                    this.marker = null;
                }
            }
        },
        hideDialog() {
            this.editItemDialog = false;
        },
        updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/project/update',
                data: {
                    "project_id": this.localItem.project_id,
                    "project_code": this.localItem.project_code,
                    "project_name": this.localItem.project_name,
                    "project_periode_month": this.period_month,
                    "project_periode_year": this.period_year,
                    "project_location": this.localItem.project_location,
                    "project_description": this.localItem.project_description,
                    "distributor_latitude": this.localItem.distributor_latitude,
                    "distributor_longitude": this.localItem.distributor_longitude,
                    // "project_status": this.localItem.project_status,
                },
            })
                .then((res) => {
                    this.loading = false;
                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000 });
                        this.$store.commit('setErrors', {});
                        this.$store.commit('setErrorEdit', {});
                        this.editItemDialog = false;
                        this.$emit('submit');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$store.commit('setErrorEdit', err.response.data.data);
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Save', life: 3000 });
                });
        },
    }
}
</script>

<style>
.map-edit {
    height: 710px;
    width: 100%;
}
</style>
